<style lang="scss" scoped>
.text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 200px;
  color: $--color-text-placeholder;
}
</style>
<template>
  <div class="page no-header">
    <div class="text">404</div>
  </div>
</template>

<script>
export default {}
</script>
